import React from 'react';

import Layout from '../components/Layout';
import pic1 from '../assets/images/provenance-book.jpg';

const IndexPage = () => (
  <Layout fullMenu page="provenance">
    <article id="main">
      <header className="provenanceHeader">
        <h2>Provenance</h2>
        <p>Volume one of the Katari Chronicles</p>
      </header>
      <section className="wrapper style3">
        <div className="inner center">
          <p>
            Delphyne is a homeopathic healer for an isolated community on a
            remote tropical island.
          </p>
          <p>
            Drakania aspires to political greatness by challenging ancient
            traditions.
          </p>
          <p>
            Tristan is a talented swordsman and military commander, desperate to
            prove himself to his father.
          </p>
          <p>
            But when two worlds collide, people’s ambitions can quickly change.
            Survival is a spur for greatness.
          </p>
          <p>War. Torture. Betrayal. Revolution. Volcanoes. Dragons.</p>
          <p>
            As Delphyne, Drakania and Tristan attempt to navigate their way
            through new circumstances, they must make choices that mean the
            difference between life and death, success and failure.
          </p>
          <a
            href="https://www.amazon.co.uk/Provenance-Katari-Chronicles-Book-1-ebook/dp/B08BLVBST4"
            className="center button fit primary"
          >
            Buy now
          </a>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={pic1} alt="" />
        </div>
        <div className="content">
          <p>
            Kokkina Nera is an active volcanic island inhabited by the
            indigenous Katari, whose fate is usually decided by the whim of the
            volcano goddess, Ibu Pertiwi. But when outsiders visit the island,
            there is dispute about the best way to react. The decisions made by
            the Katari will cause life or death, survival or extinction.
          </p>
          <p>
            Meanwhile, war is brewing in The Lowelands. The dukes are forced to
            protect their lands from the ambitions of a newly crowned ruler,
            driving them to a desperate search for resources that could give
            them security. Provenance is a journey of discovery, conflict,
            difference and adaptation. And dragons and swords and fighting and
            “stuff”.
          </p>
        </div>
      </section>

      <section id="three" className="wrapper style3 special">
        <div className="inner">
          <header className="major">
            <h2>Reviews of Provenance</h2>
          </header>
          <ul className="features">
            <li className="">
              <p>
                “Dominic M. Williams has created a world worth escaping to.”
              </p>
              <span>
                <a
                  href="http://www.rhidianbrook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Rhidian Brook
                </a>
                <br />
                International best-selling author
              </span>
            </li>
            <li className="">
              <p>
                “Provenance had me in its clutches from page 1. The characters’
                depth grabs you and political intrigue, military manoeuvring and
                Dominic’s powerful realism keeps you hooked until the end. I
                fear we all have a new literary addiction on our hands.”{' '}
              </p>
              <span>JJ Featherstone</span>
            </li>
            {/* <li className="">
              <p>
                Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem
                tincidunt nullam amet leo Aenean ligula consequat consequat.
              </p>
              <span>Arcu accumsan</span>
            </li>
            <li className="">
              <p>
                Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem
                tincidunt nullam amet leo Aenean ligula consequat consequat.
              </p>
              <span>Arcu accumsan</span>
            </li> */}
          </ul>
        </div>
      </section>
    </article>
  </Layout>
);

export default IndexPage;
